<template>
	<div id="cadastro">
		<section>
			<div class="cadastro-img">
				<img src="@/assets/img/logo/logo-fundo.svg" alt="Logo KanjiAnki" />
			</div>
			<form class="form-cadastro" @submit.prevent="cadastro" autocomplete="off">
				<input
					autocomplete="nope"
					type="text"
					name="field"
					placeholder="Name"
					v-model="form.name"
					required
				/>
				<input
					autocomplete="false"
					type="email"
					name="email"
					placeholder="E-mail"
					v-model="form.email"
					required
				/>
				<input
					autocomplete="new-password"
					type="password"
					name="password"
					placeholder="Password (Least 6 characters)"
					v-model="form.password"
					required
				/>
				<button :disabled="$v.form.$invalid">Create an account</button>
			</form>
			<div class="cadastro-links">
				<router-link to="/login">Back</router-link>
			</div>
			<LinksFooter />
		</section>
	</div>
</template>

<script>
	import firebase from 'firebase';
	import { required, email, minLength } from 'vuelidate/lib/validators';
	import LinksFooter from '@/components/elementos/LinksFooter.vue';
	export default {
		name: 'Cadastro',
		components: {
			LinksFooter,
		},
		data() {
			return {
				form: {
					name: '',
					email: '',
					password: '',
				},
				error: null,
			};
		},
		validations: {
			form: {
				name: { required },
				email: { required, email },
				password: { required, minLength: minLength(6) },
			},
		},
		methods: {
			// cadastrarLista() {
			// 	const data = {
			// 		nomeLista: 'Numbers',
			// 		lista: [
			// 			{
			// 				id: 1,
			// 				kanji: '一',
			// 				hiragana: 'いち',
			// 				significado: 'One',
			// 				acertou: false,
			// 			},
			// 			{
			// 				id: 2,
			// 				kanji: '二',
			// 				hiragana: 'に',
			// 				significado: 'Two',
			// 				acertou: false,
			// 			},
			// 			{
			// 				id: 3,
			// 				kanji: '三',
			// 				hiragana: 'さん',
			// 				significado: 'Three',
			// 				acertou: false,
			// 			},
			// 			{
			// 				id: 4,
			// 				kanji: '四',
			// 				hiragana: 'よん・し',
			// 				significado: 'Four',
			// 				acertou: false,
			// 			},
			// 			{
			// 				id: 5,
			// 				kanji: '五',
			// 				hiragana: 'ご',
			// 				significado: 'Five',
			// 				acertou: false,
			// 			},
			// 			{
			// 				id: 6,
			// 				kanji: '六',
			// 				hiragana: 'ろく',
			// 				significado: 'Six',
			// 				acertou: false,
			// 			},
			// 			{
			// 				id: 7,
			// 				kanji: '七',
			// 				hiragana: 'なな・しち',
			// 				significado: 'Seven',
			// 				acertou: false,
			// 			},
			// 			{
			// 				id: 8,
			// 				kanji: '八',
			// 				hiragana: 'はち',
			// 				significado: 'Eight',
			// 				acertou: false,
			// 			},
			// 			{
			// 				id: 9,
			// 				kanji: '九',
			// 				hiragana: 'きゅう',
			// 				significado: 'Nine',
			// 				acertou: false,
			// 			},
			// 			{
			// 				id: 10,
			// 				kanji: '十',
			// 				hiragana: 'じゅう',
			// 				significado: 'Ten',
			// 				acertou: false,
			// 			},
			// 		],
			// 	};
			// 	firebase
			// 		.database()
			// 		.ref('users')
			// 		.child(firebase.auth().currentUser.uid)
			// 		.push(data)
			// 		.then(() => {
			// 			this.$router.push('/');
			// 		});
			// },
			cadastro() {
				this.$store.commit('SET_ADICIONAR_LOADING');
				firebase
					.auth()
					.createUserWithEmailAndPassword(this.form.email, this.form.password)
					.then((data) => {
						data.user
							.updateProfile({
								displayName: this.form.name,
							})
							.then(() => {
								// this.cadastrarLista();
								this.$router.push('/');
							});
					})
					.catch((err) => {
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: 'Error',
							mensagem: err.message,
						});
					})
					.finally(() => this.$store.commit('SET_REMOVER_LOADING'));
			},
		},
	};
</script>
