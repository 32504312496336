<template>
	<div id="password">
		<section>
			<div class="password-img">
				<img src="@/assets/img/logo/logo-fundo.svg" alt="Logo KanjiAnki" />
			</div>
			<form class="form-password" @submit.prevent="password">
				<input
					type="email"
					placeholder="E-mail"
					v-model="form.email"
					required
				/>
				<button :disabled="$v.form.$invalid">Reset password</button>
			</form>
			<div class="password-links">
				<router-link to="/login">Back</router-link>
			</div>
			<LinksFooter />
		</section>
	</div>
</template>

<script>
	import firebase from 'firebase';
	import { required, email } from 'vuelidate/lib/validators';
	import LinksFooter from '@/components/elementos/LinksFooter.vue';
	export default {
		name: 'Password',
		components: {
			LinksFooter,
		},
		data() {
			return {
				form: {
					email: '',
				},
				error: null,
			};
		},
		validations: {
			form: {
				email: { required, email },
			},
		},
		methods: {
			password() {
				this.$store.commit('SET_ADICIONAR_LOADING');
				firebase
					.auth()
					.sendPasswordResetEmail(this.form.email, {
						url: process.env.VUE_APP_API_BASE,
					})
					.then(() => {
						const email = this.form.email;
						this.form.email = '';
						this.$router.push('/login');
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: ';)',
							mensagem: `An e-mail has been sent to ${email}`,
						});
					})
					.catch((err) => {
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: 'Error',
							mensagem: err.message,
						});
					})
					.finally(() => this.$store.commit('SET_REMOVER_LOADING'));
			},
		},
	};
</script>
